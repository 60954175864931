<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("exchange_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('exchange_page.form_desc')"
    ></div>
    <div  class="page__content" v-if="getModuleConfig('exchange_page.content')" v-html="getModuleConfig('exchange_page.content')"></div>
    <div class="pointes-list">
      <PointCard
        v-for="item in gifts"
        :key="item.id"
        :item="item"
        @exchange="handleExchange"
      ></PointCard>
      <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
      <Popup ref="popupUsage">
        <div slot="title">
          是否確定要使用
          <span class="s-primary">{{ popup.point.toLocaleString() }}</span>
          點
        </div>
        <div class="s-mt-5 s-mb-5 s-text-center">
          請注意，點數一經使用即無法取消
        </div>
        <div slot="actions" class="s-flex s-space-x-2">
          <button
            class="s-btn s-liff-theme-btn-outline s-btn-sm"
            type="button"
            @click="$refs.popupUsage.close()"
          >
            取消
          </button>
          <button
            class="s-btn s-liff-theme-btn s-btn-sm"
            type="button"
            @click="handleChooseUsage"
          >
            確定
          </button>
        </div>
      </Popup>
    </div>
  </div>
</template>

<script>
import PointCard from "@/pages/Dashboard/Point/PointCard";
import Popup from "@/components/Page/Liff/Shared/Popup";
import PointPagination from "@/pages/Liff/Point/PointPagination";
import pointApi from '@/apis/liff/v2/point'
import { mapActions } from "vuex";
import pointMixin from "@/mixins/liff/point";

export default {
  mixins: [pointMixin],
  components: {
    PointCard,
    Popup,
    PointPagination,
  },
  data() {
    return {
      currentGift: null,
      gifts: [
        /* {
         *   id: 1,
         *   name: "珐瑯不銹鋼 雙層隨行杯",
         *   subtitle: "藍色",
         *   point: 10000,
         *   status: STATUS.AVAILABLE,
         * },
         * {
         *   id: 2,
         *   name: "珐瑯不銹鋼 雙層隨行杯",
         *   subtitle: "藍色",
         *   point: 0,
         *   status: STATUS.AVAILABLE,
         * },
         * {
         *   id: 3,
         *   name: "珐瑯不銹鋼 雙層隨行杯",
         *   subtitle: "藍色",
         *   point: 10000,
         *   status: STATUS.NOT_ENOUGH_POINT,
         * },
         * {
         *   id: 4,
         *   name: "珐瑯不銹鋼 雙層隨行杯",
         *   subtitle: "藍色",
         *   point: 10000,
         *   status: STATUS.NOT_ENOUGH_POINT,
         * },
         * {
         *   id: 5,
         *   name: "珐瑯不銹鋼 雙層隨行杯",
         *   subtitle: "藍色",
         *   point: 10000,
         *   status: STATUS.NOT_ENOUGH_POINT,
         * },
         * {
         *   id: 6,
         *   name: "珐瑯不銹鋼 雙層隨行杯",
         *   subtitle: "藍色",
         *   point: 10000,
         *   status: STATUS.NOT_ENOUGH_POINT,
         * },
         * {
         *   id: 7,
         *   name: "珐瑯不銹鋼 雙層隨行杯",
         *   subtitle: "藍色",
         *   point: 10000,
         *   status: STATUS.NOT_ENOUGH_POINT,
         * }, */
      ],
      popup: {
        point: 0,
      },
      eventCode: this.$route.query.event ?? null,
      page: 1,
      totalPage: 1,
    };
  },
  mounted() {
    this.fetchGifts();
  },
  watch: {
    page() {
      this.fetchGifts()
    }
  },
  methods: {
    ...mapActions('liffPoint', [
      'fetchAvailablePoints',
      'fetchExpiringSoonPoints',
    ]),
    async fetchGifts() {
      let res = await pointApi.listGifts(this.eventCode, this.page);
      let gifts = res.data
      this.totalPage = res.meta.last_page

      if (!gifts || !gifts.length) {
        this.gifts = [];
        return;
      }

      this.gifts = gifts.map((g) => {
        return {
          id: g.id,
          name: g.name,
          subtitle: g.data?.sub_title || "",
          badge: g.data?.badge || "",
          point: g.point_need,
          image: g.image_url || "",
          quantity: g.quantity,
        };
      });
    },
    handleExchange(gift) {
      console.log("handleExchange");
      this.popup.point = gift.point;
      this.currentGift = gift;
      this.$refs.popupUsage.open();
    },
    async handleChooseUsage() {
      try {
        await pointApi.redeemGift(this.eventCode, this.currentGift)
        this.$swal({
          title: "兌換完成！",
          icon: "success",
          button: "確定",
        }).then(() => {
          this.fetchGifts()
          this.$refs.popupUsage.close();
        })
      } catch (error) {
        console.log(error)
      }
      this.fetchAvailablePoints({ eventCode: this.eventCode });
      this.fetchExpiringSoonPoints({ eventCode: this.eventCode });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/shared/components/_fields.scss';

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}
</style>
